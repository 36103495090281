import Api from './Api'

export const GetObjects = async () => Api().get(`reportsBuilder/objects`)

export const GetObjectsSchema = async (key) => Api().get(`reportsBuilder/object-schema/${key}`)

export const GetObjectList = async (key) => Api().get(`reportsBuilder/object-list/${key}`)

export const GetReportExcel = async (payload) => Api().post(`reportsBuilder/excel`, payload, {
    responseType: 'blob'
})

export const GetReportExcelFile = async (file_name) => Api().get(`reportsBuilder/excel-download/${file_name}`)

export const GetPreviewReportExcel = async (payload) => Api().post(`reportsBuilder/excel`, payload)

export const GetReportPdf = async (payload) => Api().post(`reportsBuilder/pdf`, payload)

export const GetAllFormats = async ({ type, key }) => Api().get(`reportsBuilder/formats/${type}/${key}`)

export const GetSingleFormats = async (id) => Api().get(`reportsBuilder/format/${id}`)

export const SaveFormat = async (payload) => Api().post(`reportsBuilder/format`, payload)